<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <card class="card-login card-white" style="margin-top: 18px;">
        <div>
          <div>
            <h4 class="text-center">
              Per procedere è necessario autenticarsi
            </h4>
            Login rapido: premi il pulsante SCANSIONA CODICE QR qui sotto, scansiona il codice QR sul tuo badge, et voilà!
          </div>
        </div>
        <div slot="footer">
          <base-button native-type="submit" class="mb-2 login-button" size="lg" block @click="windowParent.postMessage({ type: 'scanQR' }, '*')">
            SCANSIONA CODICE QR
          </base-button>
        </div>
      </card>
    </div>

    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <card class="card-login card-white">
            <div>
              <div>
                <h4 class="text-center">
                  Login con Aristeapass
                </h4>
              </div>
              <ValidationProvider
                v-slot="{ passed, failed, errors }"
                name="email"
                rules="required"
                mode="eager"
              >
                <base-input
                  v-model="formData.email"
                  required
                  placeholder="Email"
                  addon-left-icon="tim-icons icon-email-85"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  @input="apiErrors = []"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ passed, failed, errors }"
                name="password"
                rules="required"
                mode="eager"
              >
                <base-input
                  v-model="formData.password"
                  required
                  placeholder="Password"
                  addon-left-icon="tim-icons icon-lock-circle"
                  type="password"
                  :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                  @input="apiErrors = []"
                />
              </ValidationProvider>

              <div v-if="apiErrors.length > 0">
                <label v-for="error in apiErrors" :key="error" class="error">
                  {{ error }}
                </label>
              </div>
            </div>

            <div slot="footer">
              <base-button native-type="submit" class="mb-2 login-button" :loading="loginLoading" size="lg" block>
                INVIA
              </base-button>
            </div>
          </card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>

import { extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import { mapActions } from 'vuex'
import swal from 'sweetalert2'

extend('email', email)
extend('required', required)

export default {
  metaInfo: {
    title: 'SIMI'
  },
  data () {
    return {
      formData: {
        email: '',
        password: ''
      },
      scannedData: {
        first_name: null,
        last_name: null,
        email: null,
        qrcode: null
      },
      loginLoading: false,
      apiErrors: []
    }
  },
  created () {
    this.$emit('title', 'Login')
    this.thisWindow.addEventListener('message', this.messageEventListener)
  },
  mounted () {
    // this.getUserByDevice()
  },
  beforeDestroy () {
    this.thisWindow.removeEventListener('message', this.messageEventListener)
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      loginByQr: 'auth/loginByQr'
    }),
    submit () {
      this.loginLoading = true
      this.login(this.formData).then((res) => {
        // this.$notify({
        //   message: 'Login successful!',
        //   timeout: 2000,
        //   horizontalAlign: 'right',
        //   verticalAlign: 'top',
        //   type: 'success'
        // })
        swal.fire({
          title: 'Login Succesfull',
          position: 'top',
          padding: '20px 15px',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000
        })
        this.loginLoading = false
        this.$router.push(this.$route.query.redirect || '/dashboard')
      }).catch((err) => {
        this.loginLoading = false
        if (err.response.status === 422 || err.response.status === 401) {
          this.apiErrors = [err.response.data.message]
        };
      })
    },
    messageEventListener (e) {
      if (e.data.type === 'QRScanContent') {
        this.loginByQr({ qrcode: e.data.data }).then((res) => {
          // this.$notify({
          //   message: 'Login successful!',
          //   timeout: 2000,
          //   horizontalAlign: 'right',
          //   verticalAlign: 'top',
          //   type: 'success'
          // })
          swal.fire({
            title: 'Login Succesfull',
            position: 'top',
            padding: '20px 15px',
            showCancelButton: false,
            showConfirmButton: false,
            timer: 1000
          })
          this.$router.push(this.$route.query.redirect || '/dashboard')
        }).catch((err) => {
          if (err.response.status === 422 || err.response.status === 401) {
            this.apiErrors = [err.response.data.message]
          };
        })
      }
    }
  }
}
</script>
<style lang="scss">
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
.login-button {
    background: #004182;
    color: white;
}
.example-qr {
  margin-top: 8px;
  border: 1px solid #004182;

  IMG {
    width: 100%;
    margin: 0 auto;
  }
}
</style>
